import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import slugs from '../data/slugs.json';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    lang: 'en' | 'de' = 'en';
    slugsObject: any = {};

    constructor(private Router: Router) {
        this.slugsObject = slugs;
    }

    init(lang) {
        this.lang = lang;
        const routerConfig = [
            { path: '', redirectTo: lang, pathMatch: 'full' },
            ...this.Router.config.map((route) => {
                route = this.formatRoute(route);
                if (route.children) {
                    route.children = route.children.map((route) => {
                        return this.formatRoute(route);
                    });
                }
                return route;
            })
        ];
        this.Router.resetConfig(routerConfig);
    }

    formatRoute(route) {
        if (route.path) route.path = this.slugs(route.path);
        if (route.redirectTo) route.redirectTo = this.slugs(route.redirectTo);
        return route;
    }

    slugs(path, lang?) {
        if (!path) return '';
        for (const key in this.slugsObject) {
            const slugObject = this.slugsObject[key];
            path = path.replace(new RegExp('{' + key + '}', 'gi'), slugObject[lang || this.lang]);
        }
        return path;
    }

    getSlugKey(slug) {
        for (const key in this.slugsObject) {
            const slugObject = this.slugsObject[key];
            if (slugObject[this.lang] === slug) return key;
        }
    }
}
