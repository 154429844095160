import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslatorService } from './services/translator.service';
import { RoutingService } from './services/routing.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    firstRouterCycleDone: boolean = false;

    constructor(
        @Inject(DOCUMENT) doc: Document,
        @Inject(LOCALE_ID) locale: string,
        renderer: Renderer2,
        private TitleService: Title,
        public Router: Router,
        private Renderer: Renderer2,
        private PopoverConfig: NgbPopoverConfig,
        private TranslatePipe: TranslatePipe,
        private TranslatorService: TranslatorService,
        private RoutingService: RoutingService,
        private MetaService: Meta
    ) {
        renderer.setAttribute(doc.documentElement, 'lang', this.TranslatorService.getCurrentLanguage());
        this.MetaService.addTag({
            name: 'description',
            content: this.TranslatePipe.transform('meta_description')
        });
    }

    ngOnInit(): void {
        // listen to router events
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.handleRouteChange(route);
                if (this.firstRouterCycleDone) {
                    this.handleRouteChange(route);
                } else {
                    this.firstRouterCycleDone = true;
                    this.setReady(true);
                }
            }
        });
    }

    public handleRouteChange(route) {
        let title = this.TranslatePipe.transform('meta_title');
        let routeTitle = route?.data?.title;
        if (route?.firstChild?.data?.title) routeTitle = route.firstChild.data.title;
        if (routeTitle) {
            title = `${this.TranslatePipe.transform(routeTitle)} | ${title}`;
        }
        if (routeTitle !== 'async') this.TitleService.setTitle(title);
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }
}
