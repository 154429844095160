<div class="page">

    <div class="container">

        <ng-container *ngIf="lang != 'de'">
            <h1>Privacy policy</h1>

            <p>
                DATA PROTECTION STATEMENT (PURSUANT TO THE LAW OF 02.12.1992) <br>
                We appreciate your interest in our website. As we take the protection of your privacy very seriously, we would like to inform you in detail of what we do with your data.
            </p>

            <h2>§ 1 General comments
            </h2>

            <p>Your personal data (e.g. title, name, address, e-mail address, telephone number, bank account, credit card number) are processed by us in accordance with the provisions of the Belgian Data Protection Act (Law of 02.12.1992). The following provisions provide information on how, to what extent and for what purpose we record, process and use personal data. This data protection statement applies solely to our own web pages. Should you use a link to access pages on other websites, please refer to these for information on how your personal data is handled.
            </p>

            <h2>§ 2 Customer data
            </h2>

            <p>Your personal data, insofar as these are required for establishing, specifying the content of or modifying the contractual relationship, are used exclusively for contract processing. For example, your name and address have to be communicated to the shipping company for the goods ordered by you to be delivered. Your data will not be passed on to any third party outside the scope of contract processing without your explicit consent or without a legal basis.
            </p>

            <p>Once the contract has been fulfilled, your data are barred from further use. Once all fiscal and commercial obligations have been completed, these data are deleted, insofar as you have not explicitly consented to their further use.
            </p>

            <h2>§ 3 Usage profiles
            </h2>

            <p>For the purpose of advertising, market research or for tailoring our offers, we compile pseudonymised usage profiles based on usage data. These data include details on the begin, end and scope of customer visits to our web pages and product offerings. You are entitled to object to your data being used this way. Usage profiles contain no link to your personal data
            </p>

            <h2>§ 4 Information on cookies
            </h2>

            <p>(1) We use cookies as a way of optimising our web presence. The cookies used are small data files temporarily stored on your computer and deleted once you close your browser. Other cookies remain on your computer (long-term cookies) and are recognised on each new visit, helping to provide you with better access to our website.
            </p>      
            
            <p>(2) You can prevent cookies being stored on your disk drive by selecting "Block cookies" in your browser options. This may however lead to functional restrictions with regard to our offerings.
            </p>

            <h2>§ 5 Newsletter
            </h2>

            <p>On registering to receive the newsletter, you consent to your e-mail address being used for advertising purposes. You can terminate this use by de-registering. De-registration is possible at any time. You have specifically given the following consent, possibly in conjunction with ordering:
            </p>

            <p>Newsletter registration
            </p>        

            <p>You may revoke your consent at any time, with such taking effect in the future. Should you no longer wish to receive the newsletter, please use the de-registration link in the newsletter.
            </p>

            <h2>§ 6 Information - correcting/deleting/ blocking your data
            </h2>

            <p>You are entitled to be informed about stored data regarding your person or your pseudonym and, where appropriate, to have these data corrected, deleted or blocked free of charge.
            </p>

            <p>You are entitled, on presenting proof of your identity, to receive the following from us:
            </p>

            <p>a) confirmation that your data have / have not been processed, and at a minimum details of the purpose(s) of such processing, of the categories of data undergoing such processing, and the categories of data recipients,
            </p>

            <p>b) notification in an understandable form of the data undergoing processing, and available information on the origin of the data,
            </p>

            <p>c) information on the possibility of submitting complaints
            </p>

            <p>For this purpose, please send a signed and dated request to the data manager (see below).
            </p>

            <p>The information will be communicated to you as soon as possible, at the latest forty-five days after receipt of the request.
            </p>

            <p>The information may also, when requested, be sent electronically.
            </p>

            <p>Please send requests for information to:
            </p>

            <h2>§ 7 Data correction
            </h2>

            <p>You are entitled to:
            </p>

            <p>
                have all erroneous data pertaining to your person corrected free of charge; <br>
                to oppose having data pertaining to you processed on serious, legitimate grounds specific to your personal situation; this does not apply insofar as the legitimacy of the processing is backed by the grounds set forth in Article 5.b and c of the Law of 08.12.1992. <br>
                to have, at no cost to you, all personal data deleted or barred from use, which are incomplete or not applicable to the processing purpose; of which the storage, transmission or archival is forbidden; or which have been archived for a period longer than permitted, <br>
                To exercise the rights set forth in § 7, you must send a signed and dated request to the person responsible for the processing. 
            </p>

            <p>Within one month of submitting the request, the person responsible for the processing shall notify you of the correction or deletion of the data in question.
            </p>

            <h2>§ 8 Google Analytics
            </h2>

            <p>"This website use Google Analytics, a web analysis service of Google Inc. ("Google"). Google Analytics uses so-called "cookies", i.e. text files which are stored on your computer and enable an analysis of how you have used the website. Data on your website use generated by the cookie (including your IP address, which will however have been anonymised using the _anonymizeIp() method with the result that it can no longer be assigned to a particular connection) are transferred to a Google server in the USA and stored there. Google uses this data to analyse your use of the website, to generate reports on website activities for website owners, and to render other services associated with website and internet use. Google may also pass this information on to third parties, insofar as this is legally required or when third parties process it in behalf of Google.
            </p>

            <p>Google will under no circumstances establish a link between your IP address and other Google data. You can prevent the installation of cookies through setting the corresponding option in your browser software; we would like however to point out that in such a case you might not be able to benefit from all functions of this website. Through your use of this website, you agree to letting Google process the data gathered in the manner and for the purpose described above. You may prevent any future gathering of data by Google Analytics by installing a deactivation add-on (<a target="_blank" href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>) for your browser."
            </p>
        </ng-container>



        <ng-container *ngIf="lang == 'de'">
            <h1>Datenschutz-Bestimmungen</h1>

            <p>DATENSCHUTZERKLÄRUNG (GESETZ VOM 02.12.1992) <br>
                Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
            </p>

            <h2>§ 1 Allgemeines
            </h2>

            <p>Ihre personenbezogenen Daten (z.B. Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer, Bankverbindung, Kreditkartennummer) werden von uns nur gemäß den Bestimmungen des belgischen Datenschutzrechts (Gesetz vom 02.12.1992) verarbeitet. Die nachfolgenden Vorschriften informieren Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten. Diese Datenschutzerklärung bezieht sich nur auf unsere Webseiten. Falls Sie über Links auf andere Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen Umgang mit Ihren Daten.
            </p>

            <h2>§ 2 Kundendaten
            </h2>

            <p>Ihre personenbezogenen Daten, soweit diese für die Begründung, inhaltliche Ausgestaltung oder Änderung des Vertragsverhältnisses erforderlich sind, werden ausschließlich zur Vertragsabwicklung verwendet. So muss z.B. zur Zustellung der Waren Ihr Name und Ihre Anschrift an den Warenlieferanten weitergegeben werden. Ohne Ihre ausdrückliche Einwilligung oder ohne gesetzliche Grundlage werden Ihre Daten nicht an außerhalb der Vertragsabwicklung stehende Dritte weitergegeben.
            </p>

            <p>Nach vollständiger Vertragsabwicklung werden Ihre Daten für die weitere Verwendung gesperrt. Nach Ablauf der steuer- und handelsrechtlichen Vorschriften werden diese Daten gelöscht, sofern Sie nicht ausdrücklich in die weitere Nutzung eingewilligt haben.
            </p>

            <h2>§ 3 Nutzungsprofile
            </h2>

            <p>Zum Zwecke der Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung unserer Angebote erstellen wir unter Verwendung von Nutzungsdaten pseudonymisierte Nutzungsprofile. Unter Nutzungsdaten fallen insbesondere Angaben über Beginn und Ende sowie über den Umfang der jeweiligen Nutzung unserer Seiten und Angebote durch den Käufer. Sie sind berechtigt, dieser Art der Nutzung Ihrer Nutzungsdaten zu widersprechen. Die Nutzungsprofile werden nicht mit Ihren personenbezogenen Daten zusammengeführt. 
            </p>

            <h2>§ 4 Informationen über Cookies
            </h2>

            <p>(1) Zur Optimierung unseres Internetauftritts setzen wir Cookies ein. Es handelt sich dabei um kleine Informationsdateien, die im Arbeitsspeicher Ihres Computers gespeichert werden. Diese Cookies werden nach dem Schließen des Browsers wieder gelöscht. Andere Cookies verbleiben auf Ihrem Rechner (Langzeit-Cookies) und erkennen ihn beim nächsten Besuch wieder. Dadurch können wir Ihnen einen besseren Zugang auf unsere Seite ermöglichen.
            </p>

            <p>(2) Das Speichern von Cookies auf Ihrer Festplatte können Sie verhindern, indem Sie in Ihren Browser-Einstellungen "Cookies blockieren" wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote zur Folge haben.
            </p>

            <h2>§ 5 Newsletter
            </h2>

            <p>Bei Anmeldung zum Newsletter wird Ihre E-Mail-Adresse mit Ihrer Einwilligung für eigene Werbezwecke genutzt, bis Sie sich vom Newsletter abmelden. Die Abmeldung ist jederzeit möglich. Die nachstehende Einwilligung haben Sie gesondert oder ggf. im Verlauf des Bestellprozesses ausdrücklich erteilt:
            </p>

            <p>Newsletter Anmeldung
            </p>

            <p>Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Wenn Sie den Newsletter nicht weiter beziehen wollen, dann können Sie sich folgendermaßen abmelden: Über einen Abmeldelink im Newsletter.
            </p>

            <h2>§ 6 Auskunft - Berichtigung/Löschung/Sperrung
            </h2>

            <p>Sie haben ein Recht auf Auskunft über die zu Ihrer Person oder zu Ihrem Pseudonym gespeicherten Daten und ggf. ein Recht auf unentgeltliche Berichtigung, Löschung oder Sperrung dieser Daten.
            </p>

            <p>Sie haben das Recht, nachdem Sie den Beweis Ihrer Identität vorgelegt haben, von uns Folgendes zu erhalten:
            </p>

            <p>a) Bestätigung, dass es Verarbeitungen sie betreffender Daten gibt oder nicht gibt, und zumindest Informationen über die Zweckbestimmungen dieser Verarbeitungen, die Kategorien der Daten, die Gegenstand der Verarbeitung sind, und die Kategorien der Empfänger, an die die Daten übermittelt werden,
            </p>

            <p>b) Mitteilung in verständlicher Form über die Daten, die Gegenstand der Verarbeitung sind, und verfügbare Informationen über die Herkunft der Daten,
            </p>

            <p>c) Information über die Möglichkeit, Beschwerden einzureichen
            </p>

            <p>Zu diesem Zweck richten Sie bitten einen datierten und unterzeichneten Antrag an den Dateiverwalter (s. hiernach).
            </p>

            <p>Die Auskünfte werden unverzüglich und spätestens innerhalb fünfundvierzig Tagen nach Empfang des Antrags mitgeteilt.
            </p>

            <p>Die Auskunft kann Ihnen jedoch auch, auf Verlangen,  elektronisch erteilt werden.
            </p>

            <p>Bitte wenden Sie sich zur Auskunftserteilung an:
            </p>

            <h2>§ 7 Berichtigung
            </h2>

            <p>Sie haben das Recht:
            </p>

            <p>
                kostenlos alle fehlerhaften sie betreffenden Daten berichtigen zu lassen; <br>
                sich aus schwerwiegenden, legitimen, sich aus ihrer besonderen Situation ergebenden Gründen dagegen zu widersetzen, dass sie betreffende Daten verarbeitet werden; dies gilt nicht, wenn die Rechtmäßigkeit der Verarbeitung auf die in Artikel 5 Buchstabe b) und c) des Gesetzes vom 08.12.1992 erwähnten Gründe gestützt ist. <br>
                kostenlos die Löschung oder das Verbot der Verwendung aller sie betreffenden personenbezogenen Daten zu erwirken, die unter Berücksichtigung des Verarbeitungszwecks unvollständig oder nicht sachdienlich sind, deren Speicherung, Mitteilung oder Aufbewahrung verboten ist oder die über den erlaubten Zeitraum hinaus aufbewahrt worden sind. <br>
                Um die in § 7 erwähnten Rechte auszuüben, müssen Sie einen datierten und unterzeichneten Antrag an den für die Verarbeitung verantwortlichen Person richten.
            </p>

            <p>Innerhalb eines Monats nach Einreichen des Antrags teilt der für die Verarbeitung Verantwortliche die Berichtigungen oder Löschungen mit.
            </p>

            <h2>§ 8 Google Analytics
            </h2>

            <p>„Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse, die jedoch mit der Methode _anonymizeIp()  anonymisiert wird, so dass sie nicht mehr einem Anschluss zugeordnet werden kann) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.
            </p>

            <p>Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.Sie können der Erhebung der Daten durch Google-Analytics mit Wirkung für die Zukunft widersprechen, indem sie ein Deaktivierungs-Add-on (<a target="_blank" href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>) für Ihren Browser installieren.“</p>



        </ng-container>
        
    </div>

</div>