<footer>
    <div class="container">
        <div class="descr descr-left">Frigera Group &copy; {{year}}</div>
        <div class="descr descr-center"><img src="/assets/img/misc/logo-footer.svg" alt="Logo Frigera"></div>
        <div class="descr descr-right">
            <a [routerLink]="['/{privacy-policy}' | slugs | prefixLang]">{{'footer_privacy_policy' | translate}}</a>
            <a [routerLink]="['/{terms}' | slugs | prefixLang]">{{'footer_terms' | translate}}</a>
            <a [routerLink]="['/{cookie-policy}' | slugs | prefixLang]">{{'footer_cookie_policy' | translate}}</a>
        </div>
    </div>
    <div class="flanders-invest" *ngIf="false">
        <div class="title">{{'footer_flanders_invest_descr' | translate}}</div>
        <img src="/assets/img/misc/flanders-invest.png" alt="Flanders Investment & Trade">
    </div>
</footer>