<div class="page">

    <div class="container">
        <h1>Cookie policy</h1>

        <p>We attach great importance to the security and confidentiality of your personal data that we collect and use.
            This cookie policy informs you about the use of cookies. This cookie policy was last updated on the 14th of
            May 2021.</p>

        <p>By continuing to use this platform after you have been notified of our use of cookies, you agree that you
            have read all information about such use.</p>

        <p>If you would like more (general) information about how we process your personal data, other than through
            cookies e.g. with regard to your communication with us via e-mail, telephone, fax and social media, we
            kindly refer you to our privacy policy</p>

        <h2>1. Who are we?</h2>

        <h3>1.1. “We” in this cookie policy refers to FRIGERA AG:</h3>

        <p>
            Name: friGERA AG <br>
            Address: Gremelsloweg 1044, B-3680 Maaseik - BELGIUM <br>
            Company number: 0461.415.043 <br>
            E-mail: {{'info{at}frigera{dot}com' | emailObfuscation}} <br>
            Telephone: +32 89 700 440 <br>
            Fax: +32 89 700 441
        </p>

        <h3>1.2. We are responsible for the collection and use of your personal data in the manner explained in this
            cookie policy. If you have any questions about this, please contact us by e-mail
            ({{'info{at}frigera{dot}com' | emailObfuscation}}).</h3>

        <h3>1.3. In certain circumstances, third parties may (also) be responsible for the processing of your personal
            data for example, if you click on a link and leave our platform. In that case, we recommend that you consult
            the privacy policies of these third parties.</h3>

        <h2>2. What are cookies?</h2>

        <h3>2.1. Cookies are small text files that are stored on your device and contain certain information or personal
            data. For more information about cookies, please refer to www.allaboutcookies.org.</h3>

        <h2>3. What types of cookies do we distinguish?</h2>

        <h3>3.1. We classify cookies according to their origin, purpose and lifespan. Below we explain what this means.
        </h3>

        <h3>3.2. Origin: we distinguish cookies depending on who places cookies.</h3>

        <p>
            First party cookies are placed and managed by us; and <br>
            Third party cookies are placed and managed by a third party
        </p>

        <h3>3.3. Purpose: we distinguish different cookies according to their purpose.</h3>

        <p>
            Strictly necessary cookies are essential in order to use our platform or app; <br>
            Functional cookies collect information about your preferences to improve the functioning of our platform or
            app to you.; <br>
            Analytical cookies collect information about the use of our platform or app to improve our platform or app
            to the benefit of all visitors or users; and <br>
            Marketing cookies collect information about your browsing behavior to make content and advertising more
            relevant to you.
        </p>

        <h3>3.4. Lifespan: we differentiate cookies according to how long they are stored.</h3>

        <p>
            Session cookies are stored in the your browser or app during the session and are deleted when closing your
            browser or app; and <br>
            Persistent cookies are stored longer than the session and are not deleted until they have reached their
            expiry date, a new version of the cookie is placed or the cookie is deleted manually.
        </p>

        <h2>4. Which cookies do we use?</h2>

        <h3>4.1. When you visit our platform, only strictly necessary cookies are placed on your device.</h3>

        <h3>4.2. Below we give you an overview of the cookies that we place or use.</h3>

        <table>
            <tr>
                <th>Name</th>
                <th>Origin</th>
                <th>Purpose</th>
                <th>Lifespan</th>
            </tr>
            <tr>
                <td>_GA</td>
                <td>Google Analytics</td>
                <td>Statistics</td>
                <td>2 Years</td>
            </tr>
        </table>

        <h2>5. With whom do we share your personal data?</h2>

        <h3>5.1. In principle, we do not share your personal data with anyone other than the persons who work for us, as
            well as with the suppliers who help us process your personal data. Anyone who has access to your personal
            data will always be bound by strict legal or contractual obligations to keep your personal data safe and
            confidential. This means that only the following categories of recipients will receive your personal data:
        </h3>

        <p>
            You; <br>
            Your employer or business partners, but only when this is necessary for the purposes mentioned above (e.g.
            when your employer is our supplier or customer); <br>
            Our employees and suppliers; and <br>
            Government or judicial authorities to the extent that we are obliged to share your personal data with them
            (e.g. tax authorities, police or judicial authorities).
        </p>

        <h3>5.2. We do not transfer your personal data outside the European Economic Area (EEA) (the European Economic
            Area consists of the EU, Liechtenstein, Norway and Iceland). We will only transfer your personal data
            outside the EEA if you or your employer, as a customer or supplier, have offices outside the EEA with which
            we need to communicate. If a transfer were to take place, we will take sufficient safeguards to protect your
            personal data during the transfer (e.g. by entering into an agreement based on standard data protection
            clauses approved by the European Commission).</h3>

        <h2>6. How long do we keep your personal data?</h2>

        <h3>6.1. Your personal data will only be processed for as long as necessary to achieve the purposes described
            above or, when we have asked you for your consent, until you withdraw your consent. As a general rule,
            session cookies are deleted when closing your browser or app and persistent cookies are only deleted when
            they have reached their expiry date, a new version of the cookie is placed or the cookie is deleted
            manually.
        </h3>

        <h2>7. What if you don't want cookies?
        </h2>

        <h3>7.1. Browser settings: you can adjust your browser settings or device settings to prevent cookies from being
            placed on your device. How you adjust these settings and your consent to use the cookies depends on the
            browser you are using. Click on the browser you use below to learn more about how you should adjust the
            cookie settings.
        </h3>

        <p>How to delete and manage cookies in Internet Explorer (<a target="_blank"
                href="https://support.microsoft.com/nl-nl/windows/cookies-verwijderen-en-beheren-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/nl-nl/windows/cookies-verwijderen-en-beheren-168dab11-0753-043d-7c16-ede5947fc64d</a>)
            <br>
            How to delete and manage cookies in Mozilla Firefox (<a target="_blank"
                href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen">https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen</a>)
            <br>
            How to delete and manage cookies in Chrome (<a target="_blank"
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=nl">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=nl</a>)
            <br>
            How to delete and manage cookies in Safari (iOS) (<a target="_blank"
                href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a>) <br>
            How to delete and manage cookies in Apple Safari (MacOS) (<a target="_blank"
                href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac</a>)
        </p>

        <h3>7.2. Delete: you can manually delete placed cookies from your device. To do so, go to your browser settings
            or device settings.</h3>

        <h3>7.3. Caution: please note that changing the browser settings or deleting cookies may result in certain parts
            of our platform no longer working properly. Please also note that you may need to change the browser
            settings of multiple browsers if you use different browsers.</h3>

        <h2>8. Your rights regarding your personal data
        </h2>

        <h3>8.1. You have certain rights related to the processing of your personal data: the right of access,
            rectification, erasure, and data portability as well as the right to object to or limit the processing of
            your personal data and to withdraw your consent. To withdraw your consent to the placement of cookies,
            please refer to article 7. To exercise one of your rights, you can submit a written request to
            {{'info{at}frigera{dot}com' | emailObfuscation}} stating the right to which your request relates. If you are
            still dissatisfied, you have the right to contact the competent data protection authority, i.e. the Belgian
            supervisory authority (<a target="_blank"
                href="www.gegevensbeschermingsautoriteit.be">www.gegevensbeschermingsautoriteit.be</a>).
        </h3>

        <h2>9. Changes to this cookie policy
        </h2>

        <h3>9.1. We reserve the right to change this cookie policy on our own initiative. If material changes to this
            cookie policy may affect the processing of your personal data or your previously given consent, we will ask
            your consent again when you visit or use our platform or app and we will communicate these changes to you..
        </h3>

        <h3>9.2. We invite you to consult the latest version of this cookie policy online. Our cookie policy states the
            date our cookie policy was last changed.
        </h3>

        <h2>10. How can you contact us?
        </h2>

        <h3>10.1. Should you have any further questions about the processing of your personal data , please do not
            hesitate to contact our privacy manager. You can contact our privacy manager by e-mail: <a
                href="{{'info{at}frigera{dot}com' | emailObfuscation}}">{{'info{at}frigera{dot}com' |
                emailObfuscation}}</a>
        </h3>
    </div>

</div>