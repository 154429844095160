import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteGuardService {
    constructor(private Router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        {
            if (route?.params?.lang && ['en', 'de'].indexOf(route.params.lang) === -1) {
                this.Router.navigate(['']);
                return false;
            } else return true;
        }
    }
}
