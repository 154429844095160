import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProductsComponent } from './pages/products/products.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RouteGuardService } from './services/route-guard.service';

const routes: Routes = [
    {
        path: ':lang',
        canActivate: [RouteGuardService],
        children: [
            {
                path: '',
                component: HomeComponent
                // data: { title: 'meta_title_home' }
            },
            {
                path: '{webshop}',
                component: HomeComponent,
                data: { webshop: true }
            },
            // {
            //     path: '{products}',
            //     component: ProductsComponent,
            //     data: { title: 'meta_title_products' }
            // },
            // {
            //     path: '{contact}',
            //     component: ContactComponent,
            //     data: { title: 'meta_title_contact' }
            // },
            {
                path: '{privacy-policy}',
                component: PrivacyPolicyComponent,
                data: { title: 'meta_title_privacy_policy' }
            },
            {
                path: '{terms}',
                component: TermsComponent,
                data: { title: 'meta_title_terms' }
            },
            {
                path: '{cookie-policy}',
                component: CookiePolicyComponent,
                data: { title: 'meta_title_cookie_policy' }
            }
        ]
    },
    // 404
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
