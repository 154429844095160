import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-webshop-popup',
    templateUrl: './webshop-popup.component.html',
    styleUrls: ['./webshop-popup.component.scss']
})
export class WebshopPopupComponent implements OnInit {
    constructor(public ActiveModal: NgbActiveModal, private TranslatorService: TranslatorService) {}

    lang: string = '';

    ngOnInit(): void {
        this.lang = this.TranslatorService.getCurrentLanguage();
    }
}
