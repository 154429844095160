<div class="page" class="lang-{{lang}}">

    <div id="home"></div>

    <div class="hero-wrap">
        <div class="container">
            <div class="headline-wrap">
                <h1 class="title">
                    <span class="line-1">{{'home_title_1' | translate}}</span> <br>
                    <span class="line-2">{{'home_title_2' | translate}}</span>
                </h1>
                <div class="subtitle">
                    <!-- <div>{{'home_subtitle_1' | translate}}</div> -->
                    <img src="/assets/img/visuals/est.png" alt="Est. 1974">
                    <div>{{'home_subtitle_2' | translate}}</div>
                </div>
            </div>
            <div class="scroll-wrap">
                <span>{{'home_scroll_text' | translate}}</span>
                <i class="icon icon-scroll animation--scroll"></i>
            </div>
        </div>
    </div>

    <section class="section--roots">
        <div class="animals-background"></div>
        <div class="container">
            <div class="left">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_roots_title' | translate}}</h2>
                </div>
                <div class="subtitle-wrap">
                    {{'home_roots_subtitle_1' | translate}} <span>{{'home_roots_subtitle_2' | translate}}</span>
                </div>
                <p>{{'home_roots_descr' | translate}}</p>
                <img src="/assets/img/visuals/est_dark.png" alt="Est. 1974">
            </div>
            <div class="right">
                <img src="/assets/img/visuals/meat-block.png" alt="Butcher family">
            </div>
        </div>
    </section>

    <div class="section-sep"></div>

    <section class="section--packaging">
        <div class="container">
            <div class="left">
                <div class="subtitle-wrap">
                    <span>{{'home_packaging_left_title_1' | translate}}</span> <br> {{'home_packaging_left_title_2' | translate}}
                </div>
                <img src="/assets/img/visuals/packaging.png" alt="Frigera packaging">
            </div>
            <div class="right">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_packaging_title' | translate}}</h2>
                </div>
                <div class="subtitle-wrap">
                    <span>{{'home_packaging_subtitle_1' | translate}}</span> {{'home_packaging_subtitle_2' | translate}}
                </div>
                <p>{{'home_packaging_descr_1' | translate}}</p>
                <p>{{'home_packaging_descr_2' | translate}}</p>
                <p>{{'home_packaging_descr_3' | translate}}</p>
                <p>{{'home_packaging_descr_4' | translate}}</p>
            </div>
        </div>
    </section>

    <div class="section-sep"></div>

    <section class="section--products" id="products">
        <div class="container">
            <div class="head-wrap">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_products_title' | translate}}</h2>
                </div>
                <div class="subtitle-wrap">
                    {{'home_products_subtitle_1' | translate}} <span>{{'home_products_subtitle_2' | translate}}</span> {{'home_products_subtitle_3' | translate}}
                </div>
            </div>
            <div class="products-wrap">
                <ng-container *ngFor="let section of productSections">
                <div class="products-section-wrap">
                    <h3>{{section.title}}</h3>
                    <div class="products-row">
                        <ng-container *ngFor="let product of section.products">
                            <div class="product-wrap">
                                <img [src]="product.image_url" [alt]="product.name">
                                <div class="title">{{product.name}}</div>
                                <div class="section-sep"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                </ng-container>
            </div>
            <div class="catalog-wrap">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_products_catalog_title' | translate}}</h2>
                </div>
                <a href="/assets/files/frigera_catalog.pdf" target="_blank">{{'home_products_catalog_btn' | translate}}</a>
            </div>
        </div>
    </section>

    <div class="section-sep"></div>

    <section class="section--team">
        <div class="container">
            <div class="head-wrap">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_team_title' | translate}}</h2>
                </div>
                <div class="subtitle-wrap">
                    {{'home_team_subtitle_1' | translate}} <span>{{'home_team_subtitle_2' | translate}}</span> {{'home_team_subtitle_3' | translate}}
                </div>
            </div>
            <div class="team-wrap">
                <ng-container *ngFor="let member of team">
                    <div class="member-wrap">
                        <div class="image-wrap">
                            <img class="image" [src]="member.image_url" [alt]="member.name">
                            <!-- <img class="alt-image" [src]="member.image_alt_url" [alt]="member.name"> -->
                        </div>
                        <div class="name">{{member.name}}</div>
                        <div class="role">{{member.role}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <section class="section--contact" id="contact">
        <div class="animals-background"></div>
        <div class="container">
            <div class="head-wrap">
                <div class="title-wrap">
                    <i></i>
                    <h2>{{'home_contact_title' | translate}}</h2>
                </div>
                <div class="subtitle-wrap">
                    <span>{{'home_contact_subtitle_1' | translate}}</span> {{'home_contact_subtitle_2' | translate}} <span>{{'home_contact_subtitle_3' | translate}}</span>{{'home_contact_subtitle_4' | translate}}
                </div>
            </div>
            <div class="contact-wrap">
                <div class="contact-row contact-row--descr">{{'home_contact_descr' | translate}}</div>
                <div class="contact-row contact-row--cta">
                    <div class="contact-tile contact-tile--email">
                        <div class="title-wrap">
                            <i></i>
                            <h2>{{'home_contact_mail_us' | translate}}</h2>
                        </div>
                        <a href="mailto:{{'misc_email_address' | translate}}">{{'misc_email_address' | translate}}</a>
                    </div>
                    <div class="contact-tile contact-tile--phone">
                        <div class="title-wrap">
                            <i></i>
                            <h2>{{'home_contact_call_us' | translate}}</h2>
                        </div>
                        <a href="tel:{{'misc_phone_number_formatted' | translate}}">{{'misc_phone_number' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="section-sep"></div>
    
</div>