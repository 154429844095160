import { Injectable } from '@angular/core';
import stringsData from '../data/strings.json';
import productsData from '../data/products.json';
import teamData from '../data/team.json';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    lang: any = 'en';
    constructor() { }

    public init(lang) {
        this.lang = lang;
    }

    getData(type: 'strings' | 'products' | 'team', info?: any) {
        switch (type) {
            case 'strings':
                const result = { ...stringsData };
                for (const key in stringsData) {
                    let string = stringsData[key];
                    result[key] = string[info];
                }
                return result;
            case 'products':
                return productsData.map((section) => {
                    return {
                        title: section['title_' + this.lang],
                        products: section.products.map((product) => {
                            return {
                                name: product['name_' + this.lang],
                                image_url: product.image_url
                            };
                        })
                    };
                });
            case 'team':
                return teamData.map((member) => {
                    return {
                        ...member,
                        role: member['role_' + this.lang]
                    };
                });
        }
    }
}
