import { Component, OnInit } from '@angular/core';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
    lang: string = 'en';

    constructor(public TranslatorService: TranslatorService) { }

    ngOnInit(): void {
        this.lang = this.TranslatorService.getCurrentLanguage();
    }
}
