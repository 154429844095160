<div class="nav-wrap" [class.active]="active">
    <div class="container">
        <nav>
            <a class="logo-wrap" [routerLink]="['']"><img src="assets/img/misc/logo-white.svg" alt="Frigeria Logo"></a>
            <a [routerLink]="['' | prefixLang : true]" fragment="home">
                <span (click)="navigate($event)">{{'nav_home' | translate}}</span>
            </a>
            <a [routerLink]="['' | prefixLang : true]" fragment="products">
                <span (click)="navigate($event)">{{'nav_products' | translate}}</span>
            </a>
            <a [routerLink]="['' | prefixLang : true]" fragment="contact">
                <span (click)="navigate($event)">{{'nav_contact' | translate}}</span>
            </a>
            <a [routerLink]="['webshop' | prefixLang]">
                <span>{{'nav_webshop' | translate}}</span>
            </a>
        </nav>
        <div class="right">
            <a class="phone-wrap" href="tel:{{'misc_phone_number_formatted' | translate}}">
                <i class="icon icon-phone-red"></i>
                <span>{{'misc_phone_number' | translate}}</span>
            </a>
            <div class="language-switch-wrap">
                <select [ngModel]="language" (ngModelChange)="selectLanguage($event)">
                    <ng-container *ngFor="let option of languagesOptions;">
                        <option [ngValue]="option.value">{{option.title}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="nav-spacer"></div>

<!-- mobile -->
<div class="nav-wrap--mobile">
    <img src="assets/img/misc/logo-white--mobile.svg" alt="Frigeria Logo">
    <div class="right">
        <div class="language-switch-wrap">
            <select [ngModel]="language" (ngModelChange)="selectLanguage($event)">
                <ng-container *ngFor="let option of languagesOptions;">
                    <option [ngValue]="option.value">{{option.title}}</option>
                </ng-container>
            </select>
        </div>
    </div>
    <a class="webshop-link" [routerLink]="['webshop' | prefixLang]">
        <span>{{'nav_webshop' | translate}}</span><i class="icon icon-chev-right-white"></i>
    </a>
</div>