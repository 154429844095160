import { Component, OnInit } from '@angular/core';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    lang: string = 'en';

    constructor(public TranslatorService: TranslatorService) { }

    ngOnInit(): void {
        this.lang = this.TranslatorService.getCurrentLanguage();
    }
}
