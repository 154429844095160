<div class="webshop-popup">

    <div class="content-wrap">

        <button class="close-modal-wrap" (click)="ActiveModal.dismiss()"><i class="icon icon-close-modal"></i></button>

        <div class="head-wrap">
            <div class="title-wrap">
                <i class="icon icon-webshop-cart"></i>
                <h2>{{'webshop_title' | translate}}</h2>
            </div>
        </div>

        <div class="html-wrap" [innerHTML]="'webshop_descr_1' | translate | safe : 'html'"></div>

        <ul class="list-wrap">
            <ng-container *ngFor="let code of ['articles','email','invoice','payment','processing']; index as $i">
                <li>
                    <i class="count"><i>{{$i + 1}}</i></i>
                    <div class="li-content-wrap">
                        <div class="li-html-wrap"
                            [innerHTML]="('webshop_list_content_' + ($i + 1)) | translate | safe : 'html'"></div>
                        <ng-container *ngIf="code === 'payment'">
                            <div class="payment-methods-wrap">
                                <div class="payment-method-item payment-method-item--back-transfer">
                                    <div class="payment-method-head">
                                        <i class="icon icon-bank-transfer"></i>
                                        <div>
                                            <span class="info">{{'webshop_info_discount' | translate}}</span>
                                            <h3>{{'webshop_payment_method_bank_transfer' | translate}}</h3>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="fontweight-500">{{'webshop_banktransfer_descr_1' | translate}}</span> <br>
                                        <span class="fontweight-300">{{'webshop_banktransfer_descr_2' | translate}}</span> <span
                                            class="fontweight-400">{{'webshop_banktransfer_descr_3' | translate}}</span> <br>
                                        <span class="fontweight-300">{{'webshop_banktransfer_descr_4' | translate}}</span> <span
                                            class="fontweight-400">{{'webshop_banktransfer_descr_5' | translate}}</span>
                                    </p>
                                    <div class="sep"></div>
                                    <p>
                                        <span class="fontweight-500">{{'webshop_banktransfer_descr_6' | translate}}</span> <br>
                                        <span class="fontweight-300">{{'webshop_banktransfer_descr_7' | translate}}</span> <span
                                            class="fontweight-400">{{'webshop_banktransfer_descr_8' | translate}}</span> <br>
                                        <span class="fontweight-300">{{'webshop_banktransfer_descr_9' | translate}}</span> <span
                                            class="fontweight-400">{{'webshop_banktransfer_descr_10' | translate}}</span>
                                    </p>
                                </div>
                                <div class="or-wrap">
                                    <span class="line"></span>
                                    <span class="or">{{'webshop_or' | translate}}</span>
                                    <span class="line"></span>
                                </div>
                                <div class="payment-method-item payment-method-item--paypal">
                                    <div class="payment-method-head">
                                        <i class="icon icon-paypal"></i>
                                        <div>
                                            <h3>{{'webshop_payment_method_paypal' | translate}}</h3>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="fontweight-500">{{'webshop_paypal_descr_1' | translate}} </span>
                                        <a href="{{'webshop_paypal_descr_2_link' | translate}}" target="_blank" class="link link--external">{{'webshop_paypal_descr_2' | translate}}<i class="icon icon-external"></i></a>
                                    </p>
                                    <span class="or">{{'webshop_or' | translate}}</span>
                                    <p>
                                        <span class="fontweight-500">{{'webshop_paypal_descr_3' | translate}}</span>
                                    </p>
                                    <img [src]="('assets/img/misc/paypal_qr_code_' + lang + '.png') | translate" alt="qr code" class="qr">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </li>
            </ng-container>
        </ul>

        <div class="html-wrap" [innerHTML]="'webshop_descr_2' | translate | safe : 'html'"></div>

    </div>

    <div class="section-sep"></div>

</div>