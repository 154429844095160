import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from './data.service';
import { RoutingService } from './routing.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private TranslatorService: TranslatorService,
        private RoutingService: RoutingService,
        private DataService: DataService,
        private Router: Router,
        private Route: ActivatedRoute
    ) { }

    initialize() {
        return new Promise<void>((resolve, reject) => {
            let lang = localStorage.getItem('language') || 'en';
            if (window.location.pathname.indexOf('/en') === 0) {
                lang = 'en';
            }
            if (window.location.pathname.indexOf('/de') === 0) {
                lang = 'de';
            }
            localStorage.setItem('language', lang);
            this.DataService.init(lang);
            this.TranslatorService.init(lang);
            this.RoutingService.init(lang);
            resolve();
        });
    }
}
