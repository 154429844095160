import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
    status: string = '';
    hidden: boolean = false;

    constructor(public Router: Router) {
        setTimeout(() => {
            this.Router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    gtag('config', 'G-YD3Y5LX4CY', {
                        page_path: event.urlAfterRedirects
                    });
                }
            });
        }, 1);
    }

    ngOnInit(): void {
        this.status = localStorage.getItem('cookies');
        if (this.status) {
            this.hidden = true;
        }
        if (this.status === 'accepted') {
            gtag('js', new Date());
            gtag('config', 'G-YD3Y5LX4CY');
        }
    }

    accept() {
        localStorage.setItem('cookies', 'accepted');
        this.status = 'accepted';
        gtag('js', new Date());
        gtag('config', 'G-YD3Y5LX4CY');
    }

    decline() {
        localStorage.setItem('cookies', 'declined');
        this.status = 'declined';
    }
}
