import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { WebshopPopupComponent } from '../webshop-popup/webshop-popup.component';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router, RoutesRecognized } from '@angular/router';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    active: boolean = true;
    language: 'en' | 'de' = 'en';
    languagesOptions: any[] = [
        {
            title: 'EN',
            value: 'en'
        },
        {
            title: 'DE',
            value: 'de'
        }
    ];
    prevScrollpos = window.pageYOffset;
    webshopModalRef: NgbModalRef = null;

    constructor(
        private TranslatorService: TranslatorService,
        private RoutingService: RoutingService,
        private NgbModal: NgbModal,
        private Router: Router,
        private ActivatedRoute: ActivatedRoute
    ) {
        this.TranslatorService.getLanguage().subscribe((next) => {
            this.language = next;
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        let currentScrollPos = window.pageYOffset;
        if (currentScrollPos < 80 || this.prevScrollpos > currentScrollPos) {
            this.active = true;
        } else {
            this.active = false;
        }
        this.prevScrollpos = currentScrollPos;
    }

    ngOnInit(): void {
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                this.toggleWebshop(route);
            }
        });
        let route = this.ActivatedRoute.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        setTimeout(() => {
            this.toggleWebshop(route);
        }, 200);
    }

    selectLanguage(language: string) {
        window.location.href = this.getUrl(language);
    }

    getUrl(lang) {
        const langPart = `/${this.TranslatorService.getCurrentLanguage()}`;
        const newLangPart = `/${lang}`;
        let url = window.location.protocol + '//' + window.location.host + '/';
        let path = window.location.pathname.replace(langPart, newLangPart).replace(/^\/+/g, '');
        for (let i = 0; i < path.split('/').length; i++) {
            let part = path.split('/')[i];
            const key = this.RoutingService.getSlugKey(part);
            if (key) {
                part = this.RoutingService.slugs(`{${key}}`, lang);
            }
            if (i !== 0) {
                part = `/${part}`;
            }
            url += part;
        }
        return url;
    }

    navigate($event) {
        const hashElement = document?.querySelector($event?.target?.parentElement?.hash);
        if (hashElement) {
            $event.preventDefault();
            $event.stopPropagation();
            hashElement.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    toggleWebshop(route: ActivatedRouteSnapshot) {
        if (route.data.webshop) {
            this.openWebshopPopup();
        } else this.closeWebshopPopup();
    }

    openWebshopPopup() {
        if (!this.webshopModalRef) {
            this.webshopModalRef = this.NgbModal.open(WebshopPopupComponent, {
                windowClass: 'modal--1280',
                beforeDismiss: () => {
                    this.Router.navigate(['../']);
                    return true;
                }
            });
        }
    }

    closeWebshopPopup() {
        if (this.webshopModalRef) {
            this.webshopModalRef.close();
            this.webshopModalRef = null;
        }
    }
}
