<div class="page">

    <div class="container">

        <ng-container *ngIf="lang != 'de'">
            <h1>GENERAL TERMS AND CONDITIONS</h1>

            <h2>1. GENERAL</h2>
            <p>1.1. These General Terms and Conditions apply to all agreements entered into by FRIGERA Plc. (hereinafter
                referred to as "FRIGERA"), to the conclusion thereof and to all offers
                (hereinafter referred to as "Offers") or orders (hereinafter referred to as "Order(s)") accepted by
                FRIGERA for the purchase of goods produced by FRIGERA (hereinafter referred
                to as "Goods"). The other party to such an Agreement, Offers and/or Order(s) is referred to as: "the
                Customer".</p>
            <p>1.2. The application of these General Terms and Conditions will be accepted by the Customer by the mere
                fact of entering into an Agreement with FRIGERA. The Customer's general
                terms and conditions are expressly excluded, as are any terms and conditions which implicitly apply in
                trade, custom, practice or course of business. Deviations from (part of)
                these General Terms and Conditions will only be valid if and to the extent that they have been accepted
                by FRIGERA in writing.</p>
            <h2>2. OFFERS AND PRICES</h2>
            <p>2.1. All Offers are in principle without engagement, unless expressly stated otherwise in writing. Offers
                and/or orders will be binding only in so far as they have been signed by an
                authorized representative of FRIGERA and during a period of 30 days from the date of offer and/or order,
                unless expressly otherwise stated in writing, or when FRIGERA
                commences delivery. The Agreement will also enter into force at that time.</p>
            <p>2.2. Unless stated otherwise, all prices quoted by FRIGERA are exclusive: <br>
                (a) VAT, and; <br>
                (b) costs and charges of packaging, insurance and transport of the Goods, which shall be charged
                separately to the Customer.</p>
            <p>2.3. The Goods will be invoiced at the prices and on the conditions mentioned in the Agreement. FRIGERA
                expressly reserves the right to increase the price agreed if, after the
                Agreement has been entered into, one or more cost factors (including, but not limited to, the prices of
                raw materials and consumables, energy, freight and transport and labour
                costs) are increased, even if this is due to foreseeable circumstances.</p>
            <h2>3. DELIVERY</h2>
            <p>3.1 Unless otherwise agreed, delivery will be made to FRIGERA's warehouse at Gremelsloweg 1044, 3680
                Maaseik (ex works). If FRIGERA is required to organize transport, it will
                always do so as agent of the Customer, at the latter's risk. The cost of transport will be borne by the
                Customer, as provided for in Article 2.2.</p>
            <p>3.2 Delivery periods are indicative only and the time of delivery is not binding. Exceeding the term
                shall not give rise to partial or complete dissolution of the Agreement, nor to any
                damages or deduction from the price due.</p>
            <p>3.3 If FRIGERA delivers up to and including 5% more or less than the quantity of Goods ordered, the
                Customer may not refuse them. Upon reception of a message from the Customer
                that the wrong quantity of Goods has been delivered, a pro rata adjustment will be made by FRIGERA on
                the order invoice.</p>
            <p>3.4 FRIGERA may deliver the Goods in parts, which will be invoiced and paid for separately.</p>
            <h2>4. OWNERSHIP & RISK</h2>
            <p>4.1. The risk of the Goods shall pass to the Customer upon completion of delivery as indicated in article
                3.1.
            </p>
            <p>4.2 Ownership of the Goods will not pass to the Customer until FRIGERA receives full payment for the
                Goods.</p>
            <p>4.3 Until ownership of the Goods has passed to the Customer, the Customer is obliged to: <br>
                (a) store the Goods separately from all other goods in the Customer's possession so that they remain
                easily identifiable as the property of FRIGERA;<br>
                (b) not remove, damage or conceal any identifying mark or packaging on or relating to the Goods;<br>
                (c) maintain the Goods in satisfactory condition and insure them against all risks for their full price
                from the date of delivery;<br>
                (d) notify FRIGERA immediately if the Customer becomes the subject of any of the events listed in Clause
                9.1; and<br>
                (e) Provide FRIGERA with any information relating to the Goods which FRIGERA may require.</p>
            <p>4.4 Without prejudice to Clause 4.5, the Customer may resell or use the Goods in the normal course of its
                business (but not otherwise) before FRIGERA receives payment for the
                Goods. However, if the Customer resells the Goods before that time, FRIGERA's reservation of property
                will extend to all claims which replace the Goods (including all claims
                relating to the purchase price of such sale).</p>
            <p>4.5 If the Customer becomes subject to any of the events referred to in clause 9.1 and before ownership
                of the Goods passes to the Customer: <br>
                (a) the right of the Customer to resell or use the Goods in the ordinary course of its business shall
                cease immediately; and <br>
                (b) FRIGERA may at any time (at its discretion):
                <span class="indent">
                    (i) require the Customer to return all Goods in its possession which have not been resold; and <br>
                    (ii) if the Customer fails to do so immediately, enter the Customer's or a third party's premises
                    where the Goods are stored to recover them; and <br>
                    (iii) demand immediate payment for all Goods delivered to the Customer.</span>
                This is without prejudice to any other right or remedy available to FRIGERA.
            </p>
            <h2>5. PAYMENTS</h2>
            <p>5.1. FRIGERA may invoice the Goods to the Customer at any time after completion of delivery, as indicated
                in Clause 3.1.</p>
            <p>5.2. All invoices are payable within 30 days after invoice date, unless stipulated otherwise. Payment
                should be made into the bank account as indicated in writing by FRIGERA. The
                time of payment is essential.</p>
            <p>5.3. In case of non-payment of the invoice within the prescribed term, fixed damages amounting to 10% of
                the outstanding invoice amount will be due, automatically and without prior
                notice of default, from the due date, with a minimum of EUR 175, without prejudice to FRIGERA's right to
                claim a higher compensation provided it proves that a higher loss was
                actually suffered. In addition, any unpaid invoice from its due date will be increased, automatically
                and without prior notice of default, by interest on arrears at the rate of 8% per
                year on the amount of the invoice outstanding.</p>
            <h2>6. QUALITY AND COMPLAINTS</h2>
            <p>6.1. Complaints about delivered Goods, provided they are visible defects, must be notified to FRIGERA by
                registered letter within 48 hours after receipt of the Goods. In the absence
                of a notification within the above term and in accordance with the conditions laid down, the Goods will
                be deemed to have been definitively accepted by the Customer and the
                latter will lose any right to repair and/or compensation.</p>
            <p>6.2. Any hidden defects in delivered Goods must be reported to FRIGERA by registered letter within a
                period of 8 calender days from the moment when the Customer has detected or
                reasonably could have detected such defect, under penalty of forfeiture of rights of recovery.</p>
            <p>6.3. Hidden defects are only covered by the warranty for the period running from delivery of the Goods to
                expiry of the best-before date. This warranty period is in any case limited
                to 2 years counting from delivery of the Goods. After expiry of the best-before date or the maximum of 2
                years, FRIGERA shall be released from its guarantee and the risks of a
                hidden defect shall be borne by the Customer. In addition, any legal action on this ground will only be
                admissible if it is filed within a period of 6 months after the time the
                Customer discovered or reasonably could have discovered this defect.</p>
            <p>6.4. If in FRIGERA's view a complaint about a Good delivered by it is justified and the Customer has been
                able to provide sufficient proof that the defect indeed already existed at the
                time of delivery, FRIGERA will always, at its option: <br>
                (a) replace the defective Good or any part thereof;<br>
                (b) repair the defect or the defective part of the Good; or;<br>
                (c) reimburse the Customer for the purchase price of the defective Good, without the Customer being
                entitled to any compensation.</p>
            <p>6.5. In no event shall any complaint, whether in respect of Goods or services rendered or in respect of
                invoices, entitle the Customer to suspend his obligations under any Agreement
                or to demand dissolution of the Agreement, other than as provided for in Article 9.</p>

            <h2>7. LIABILITY</h2>
            <p>7.1. FRIGERA's liability in respect of Goods delivered by it will be limited to the provisions of Article
                6.4. FRIGERA will not be liable for or obliged to pay compensation for any
                immaterial, indirect or consequential damage, including (but not limited to) loss of profit, turnover,
                loss of income, production restrictions, administrative or personnel costs, an
                increase in overheads, loss of customers or claims by third parties, except in case of fraud, willful
                misconduct or gross negligence.</p>
            <p>7.2. FRIGERA will not accept any responsibility for defects caused by or arising on the Goods delivered
                through the fault or action of the Customer or third parties, due to lack of
                maintenance, normal wear and tear, improper use, lack of care or due to external causes or changes made
                by the Customer or third parties.</p>
            <p>7.3. Nor does FRIGERA accept responsibility for slight differences in color or dimensions of the
                delivered goods, in so far as they could not be avoided, are generally accepted or are
                inherent in the materials used.</p>
            <p>7.4. FRIGERA will not be liable for misprints, typing errors or counting errors and/or ambiguities in
                Offers or Orders, nor for the consequences thereof.</p>
            <p>7.5. In case of liability, FRIGERA will never be obliged to pay a higher amount in damages than the
                invoice amount (excluding VAT) of the Goods delivered, or a proportionate part
                thereof. In case FRIGERA is insured for the damage in question, its liability will be limited to the
                amount which will be paid by the insurer under its policies in the case concerned.</p>
            <h2>8. FORCE MAJEURE</h2>
            <p>8.1. Force majeure on the part of FRIGERA will in any case exist if, after entering into the Agreement,
                FRIGERA is prevented from fulfilling its obligations under this Agreement or
                its preparations in full and/or in time as a result of unforeseen circumstances, such as fire, water
                damage, natural disasters, weather conditions, strike, sit-down strikes, import and
                export restrictions, government measures, defective machinery, late delivery of the goods sold, raw
                materials and/or auxiliary materials required for that purpose, etc.; all this on
                FRIGERA's premises or on the premises of third parties from which FRIGERA is required to purchase the
                goods and/or the materials or raw materials required in whole or in
                part, as well as during storage or transport, whether or not under its own management, and furthermore
                due to all other causes arising through no fault or risk of FRIGERA.</p>
            <p>8.2. In the event of force majeure, the party affected by the force majeure shall immediately (and in any
                case within a period of 8 calendar days after the occurrence of the force
                majeure) notify the other party by registered letter. As from that moment, the execution of the
                obligations affected by the force majeure shall be suspended for the duration of the
                force majeure. The party affected by the force majeure shall do its utmost to reduce the delay to a
                minimum and shall inform the other party of the measures taken to this end. The
                parties will have the option to terminate the Agreement without any compensation from one party to the
                other if the force majeure persists or is likely to persist for a period of at
                least 90 days.</p>
            <h2>9. TERMINATION</h2>
            <p>9.1 Without limiting any other rights or remedies, either party may terminate an Agreement with immediate
                effect, without prior judicial intervention and without obligation to
                compensate, by written notice to the other party if: <br>
                (a) the other party commits a material breach of a term of the Agreement and (if such breach is
                remediable) fails to remedy that breach within 8 calendar days of being given
                written notice to do so;<br>
                (b) the other Party commits a series of minor or continuous breaches of a condition of the
                Agreement;<br>
                (c) the other party is subject to insolvency proceedings or ceases (or threatens) to operate; or<br>
                (d) the financial position of the other Party deteriorates to the extent that it could reasonably be
                expected to jeopardise that Party's ability to adequately perform its obligations
                under the Agreement.</p>
            <p>9.2 Without limiting other rights or remedies, and without prejudice to its right to compensation,
                FRIGERA may, without prior judicial intervention and without being obliged to
                compensate, but after having given notice of default: <br>
                (a) suspend delivery of the Goods under the Agreement if the Customer is subject to any of the events
                listed in Clauses 9.1(a) to 9.1(d) or if the Customer fails to pay any amount
                due under the Agreement when due; <br>
                (b) terminate an agreement with immediate effect by means of a written notification to the Customer, if
                the Customer fails to pay the amount due to FRIGERA on the due date. <br>
                In the latter case the Customer will automatically owe damages equal to 10% of the Offer or Order price
                increased by any costs incurred, without prejudice to FRIGERA's
                right to recover the damage actually suffered from the Customer.</p>
            <p>9.4. Upon termination of the Agreement for any reason, the Customer will immediately pay all outstanding
                unpaid invoices and interest to FRIGERA. FRIGERA may, at its discretion,
                require the Customer to return all Goods delivered or require immediate payment.</p>
            <p>9.5. Termination of the Agreement will not affect the rights and remedies of the parties which accrued at
                the time of termination, including the right to claim damages in respect of
                any breach of the Agreement which existed on or before the date of termination.</p>
            <p>9.6. Any provision of the Agreement expressly or impliedly intended to take effect or remain in effect
                upon or after termination shall remain in full force and effect.</p>
            <h2>10. INTELLECTUAL PROPERTY</h2>
            <p>10.1 Unless otherwise agreed in writing, all Goods, if sold in packaging, will only be sold or resold in
                the packaging supplied by FRIGERA and in no case may other trademarks be
                labeled or affixed to the Goods.</p>
            <p>10.2. The Customer acknowledges that intellectual property rights belong to FRIGERA. The Customer does
                not acquire any intellectual property rights attached to the Goods and will
                not have any right to use intellectual property rights other than in connection with the sale of the
                Goods in accordance with these terms and conditions.</p>
            <p>10.3. The Customer will not take any action that could directly or indirectly damage the intellectual
                property rights.</p>
            <p>10.4. Advertising material (if any) provided by FRIGERA, whether printed or in electronic form, belongs
                to FRIGERA and must be returned at its request.</p>
            <p>10.5. All Intellectual Property Rights to the images and photographs of the Goods are reserved by FRIGERA
                and may not be displayed, used or included in advertising materials not
                provided by FRIGERA unless expressly approved in writing in advance by FRIGERA.</p>
            <p>10.6. The restrictions mentioned in this article apply to any medium and in any way, such as print,
                websites, electronic platforms, social networks or other methods of electronic or
                digital communication.</p>
            <p>10.7. To the extent that the packaging or its design/concept is provided by the Customer, the latter
                shall be considered to own the intellectual property rights of this packaging. The
                Customer shall in such case indemnify FRIGERA against all claims (including those of third parties) due
                to an alleged infringement of these intellectual property rights.</p>
            <h2>11. NULLITY</h2>
            <p>11.1. The nullity of one or more provisions of these Terms and Conditions will not affect the validity of
                the remaining provisions. The failure of FRIGERA to invoke, in whole or in
                part, certain contractual rights will not imply any adverse recognition or waiver of rights.</p>
            <p>11.2. If any provision or part of a provision of these terms and conditions or the Agreement is or
                becomes invalid, illegal or unenforceable, it shall be deemed to have been amended
                to the extent necessary to make it valid, legal and enforceable. If such amendment is not possible, the
                provision or part of the provision in question shall be deemed to be deleted.
                An amendment or deletion of any provision or part of any provision under this clause shall not affect
                the validity and enforceability of the remainder of these terms and conditions
                or the Agreement.</p>
            <h2>12. DISPUTES</h2>
            <p>12.1. These conditions are subject to Belgian law. In case of dispute, only the courts of the district of
                Antwerp, Hasselt division will be competent.</p>
            <h2>13. PRIVACY AND PROCESSING OF PERSONAL DATA (GDPR)</h2>
            <p>13.1. From the time the Offer or Purchase Order is drawn up to invoicing, FRIGERA collects all the data
                necessary to perform the Agreement, including the Customer's name, address,
                telephone numbers, e-mail address, and any other information concerning the Customer. The Customer has
                the following rights: the right of inspection, the right of correction, the
                right of erasure, the right of restriction of processing, the right of transferability of personal data,
                the right of objection, and the right not to be subjected to automated decisionmaking. The Customer may
                invoke the aforementioned rights at any time, in which case it is sufficient to contact FRIGERA on the
                contact details indicated in the Order Form
                and/or Offer. In the event of a complaint concerning the processing of personal data, the Customer may
                contact FRIGERA with a view to an internal solution or file a complaint
                with the Data Protection Authority.</p>
        </ng-container>



        <ng-container *ngIf="lang == 'de'">

            <h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>

            <h2>1. ALLGEMEINES</h2>
            <p>1.1. Diese Allgemeinen Geschäftsbedingungen gelten für alle von FRIGERA NV (im Folgenden: "FRIGERA")
                abgeschlossenen Verträge (im Folgenden: "Vertrag"), deren
                Abschluss und für alle von FRIGERA abgegebenen Angebote (im Folgenden: "Angebote") oder angenommenen
                Bestellungen (im Folgenden: "Bestellung(en)") für den
                Kauf von FRIGERA hergestellten Waren (im Folgenden: "Waren"). Die andere Partei solcher Verträge,
                Angebote und/oder Bestellungen wird als "der Kunde" bezeichnet.</p>
            <p>1.2. Die Geltung dieser Allgemeinen Geschäftsbedingungen akzeptiert der Kunde allein durch den Abschluss
                eines Vertrags mit FRIGERA. Den Allgemeinen
                Geschäftsbedingungen des Kunden wird ausdrücklich widersprochen, ebenso wie allen Bedingungen, die
                implizit in Handel, Gewohnheit, Praxis oder Handlung gelten.
                Abweichungen von (einem Teil von) diesen Allgemeinen Geschäftsbedingungen sind nur gültig, wenn und
                soweit sie von FRIGERA schriftlich akzeptiert wurden.</p>
            <h2>2. ANGEBOTE UND PREISE</h2>
            <p>2.1. Alle Angebote sind grundsätzlich freibleibend, sofern nicht ausdrücklich und schriftlich etwas
                anderes angegeben ist. Die Angebote und/oder Bestellungen sind nur
                verbindlich, soweit sie von einem bevollmächtigten Vertreter von FRIGERA unterzeichnet wurden und dies
                für einen Zeitraum von 30 Tagen nach Angebots- und/oder
                Bestelldatum, sofern nicht ausdrücklich schriftlich etwas anderes angegeben ist oder FRIGERA mit der
                Lieferung beginnt. Zu diesem Zeitpunkt tritt auch den Vertrag in
                Kraft.</p>
            <p>2.2. Sofern nicht anders vereinbart, verstehen sich alle von FRIGERA genannten Preise zuzüglich: <br>
                a) Mehrwertsteuer und; <br>
                b) Kosten und Lasten für Verpackung, Versicherung und Transport der Waren, die dem Kunden gesondert in
                Rechnung gestellt werden.</p>
            <p>2.3. Die Waren werden zu den im Vertrag angegebenen Preisen und Bedingungen in Rechnung gestellt. FRIGERA
                behält sich ausdrücklich vor, den vereinbarten Preis zu
                erhöhen, wenn nach Vertragsabschluss ein oder mehrere Kostenpreisfaktoren (insbesondere die Preise für
                Roh- und Hilfsstoffe, Energie, Fracht und Transport sowie die
                Lohnkosten) steigen, auch wenn dies auf vorhersehbare Umstände zurückzuführen ist.</p>
            <h2>3. LIEFERUNG</h2>
            <p>3.1 Sofern nicht anders vereinbart, erfolgt die Lieferung im Lager von FRIGERA in Maaseik, Gremelsloweg
                1044 (ab Werk). Wenn FRIGERA den Transport organisieren
                muss, geschieht dies stets nach Beauftragung des Kunden auf dessen Risiko. Die Kosten für den Transport
                gehen zu Lasten des Kunden, wie in Artikel 2.2 festgelegt.</p>
            <p>3.2 Die Lieferfristen sind nur Richtwerte und die Lieferzeit unverbindlich. Eine Überschreitung der Frist
                führt weder zur teilweisen oder vollständigen Auflösung des Vertrags
                noch zu einer Entschädigung oder einem Abzug des fälligen Preises.</p>
            <p>3.3 Liefert FRIGERA bis einschließlich 5% mehr oder weniger als die bestellte Warenmenge, darf der Kunde
                diese nicht ablehnen. Nach Erhalt einer Mitteilung des Kunden,
                dass die falsche Warenmenge geliefert wurde, nimmt FRIGERA eine anteilige Anpassung der Auftragsrechnung
                vor.</p>
            <p>3.4 FRIGERA kann die Ware in Teilen liefern, die gesondert in Rechnung gestellt und bezahlt werden.</p>
            <h2>4. EIGENTUM & RISIKO</h2>
            <p>4.1 Das Risiko der Ware geht mit Abschluss der Lieferung auf den Kunden über, wie in Artikel 3.1
                angegeben.</p>
            <p>4.2 Das Eigentum an der Ware geht erst dann auf den Kunden über, wenn FRIGERA die vollständige Bezahlung
                der Ware erhalten hat.</p>
            <p>4.3 Bis zum Übergang des Eigentums an den Waren auf den Kunden ist der Kunde verpflichtet: <br>
                (a) die Waren getrennt von allen anderen Waren, die sich im Besitz des Kunden befinden, so zu lagern,
                dass sie leicht als Eigentum von FRIGERA erkennbar sind;<br>
                (b) keine Kennzeichnungen oder Verpackungen auf oder in Bezug auf die Waren zu entfernen, zu beschädigen
                oder zu verbergen;<br>
                (c) die Waren in zufriedenstellendem Zustand zu halten und sie gegen alle Risiken zum vollen Preis ab
                dem Datum der Lieferung zu versichern;<br>
                (d) FRIGERA unverzüglich zu informieren, wenn der Kunde Gegenstand eines der in Artikel 9.1 erwähnte
                Ereignisse wird; und<br>
                (e) FRIGERA alle Informationen über die Waren zur Verfügung zu stellen, die FRIGERA benötigt.</p>
            <p>4.4 Vorbehaltlich Artikel 4.5 darf der Kunde die Waren im normalen Rahmen seiner Geschäftstätigkeit (aber
                nicht anderweitig) weiterverkaufen oder verwenden, bevor
                FRIGERA die Zahlung für die Waren erhält. Verkauft der Kunde die Ware jedoch vor diesem Zeitpunkt
                weiter, erstreckt sich der Eigentumsvorbehalt von FRIGERA auf
                alle Forderungen, die die Ware ersetzen (einschließlich aller Forderungen im Zusammenhang mit dem
                Kaufpreis eines solchen Verkaufs).</p>
            <p>4.5 Wenn der Kunde einem der in Artikel 9.1 genannten Ereignisse ausgesetzt ist und bevor das Eigentum an
                den Waren auf den Kunden übergeht: <br>
                (a) hört das Recht des Kunden, die Waren im normalen Geschäftsgang weiterzuverkaufen oder zu verwenden,
                sofort auf; und <br>
                (b) kann FRIGERA jederzeit (nach eigenem Ermessen):
                <span class="indent">
                    (i) den Kunden auffordern, alle in seinem Besitz befindlichen Waren, die nicht weiterverkauft
                    wurden, zurückzugeben; und <br>
                    (ii) wenn der Kunde dies nicht unverzüglich tut, die Räumlichkeiten des Kunden oder eines Dritten,
                    in denen die Waren gelagert werden, zu betreten, um sie abzuholen;
                    und <br>
                    (iii) sofortige Zahlung aller an den Kunden gelieferten Waren verlangen.
                </span>
                Dies gilt unbeschadet aller anderen Rechte oder Rechtsmittel, die FRIGERA zur Verfügung stehen.
            </p>
            <h2></h2>
            <p>5.1. FRIGERA kann dem Kunden die Waren jederzeit nach Abschluss der Lieferung in Rechnung stellen, wie in
                Artikel 3.1 angegeben.</p>
            <p>5.2. Alle Rechnungen sind 30 Tage nach Rechnungsdatum zahlbar, sofern nichts anderes vereinbart ist. Die
                Zahlung hat auf das von FRIGERA schriftlich angegebene Bankkonto
                zu erfolgen. Der Zeitpunkt der Zahlung ist entscheidend.</p>
            <p>5.3. Bei Nichtzahlung der Rechnung innerhalb der vereinbarten Frist wird kraft Gesetzes und ohne
                vorherige Inverzugsetzung eine pauschale Entschädigung in Höhe von 10%
                des offenen Rechnungsbetrages fällig, mindestens jedoch 175 EUR und unbeschadet des Rechts von FRIGERA,
                vorbehaltlich des Nachweises eines höheren tatsächlich
                entstandenen Schadens eine höhere Entschädigung zu verlangen. Darüber hinaus wird jede unbezahlte
                Rechnung ab ihrem Fälligkeitsdatum kraft Gesetzes und ohne
                vorherige Inverzugsetzung um Verzugszinsen von 8% pro Jahr auf den ausstehenden Rechnungsbetrag erhöht.
            </p>
            <h2>6. QUALITÄT UND BEANSTANDUNGEN</h2>
            <p>6.1. Beanstandungen über gelieferte Waren, soweit sie sichtbare Mängel betreffen, müssen FRIGERA
                innerhalb von 48 Stunden nach Erhalt der Ware per Einschreiben mitgeteilt
                werden. Bei Nichtbenachrichtigung innerhalb der oben genannten Frist und gemäß den festgelegten
                Bedingungen gelten die Waren als vom Kunden endgültig angenommen
                und verliert der Kunde jegliches Recht auf Reparatur und/oder Entschädigung.</p>
            <p>6.2. Versteckte Mängel der gelieferten Ware sind FRIGERA unter Androhung des Verfalls des Regresses
                innerhalb einer Frist von 8 Kalendertagen ab dem Zeitpunkt, zu dem
                der Kunde diesen Mangel festgestellt hat oder vernünftigerweise hätte feststellen können, per
                Einschreiben anzuzeigen.</p>
            <p>6.3. Die versteckten Mängel sind nur von der Garantie für den Zeitraum von der Lieferung der Ware bis zum
                Ablauf des Ablaufdatums abgedeckt. Diese Garantiezeit ist in
                jedem Fall auf 2 Jahre ab Lieferung der Ware begrenzt. Nach Ablauf des Ablaufdatums bzw. maximal 2
                Jahren ist FRIGERA von der Garantie befreit und trägt der Kunde
                das Risiko des Auftretens eines versteckten Mangels. Darüber hinaus ist jeder Rechtsanspruch aus diesem
                Grund zulässig, wenn er innerhalb einer Frist von 6 Monaten
                nach dem Zeitpunkt geltend gemacht wird, zu dem der Kunde diesen Mangel festgestellt hat oder
                vernünftigerweise hätte feststellen können.</p>
            <p>6.4. Ist eine von FRIGERA gelieferte Ware nach Auffassung von FRIGERA zu Recht beanstandet worden und
                konnte der Kunde hinreichend nachweisen, dass der Mangel
                tatsächlich bereits zum Zeitpunkt der Lieferung vorlag, wird FRIGERA stets nach eigenem Ermessen: <br>
                (a) die mangelhafte Ware ganz oder teilweise ersetzt;<br>
                (b) den Mangel oder den defekten Teil der Ware repariert oder;<br>
                (c) dem Kunden den Kaufpreis der mangelhaften Ware erstattet, ohne dass der Kunde Anspruch auf
                Schadensersatz hat.</p>
            <p>6.5. Unter keinen Umständen berechtigt eine Beanstandung, weder in Bezug auf gelieferte Waren oder
                Dienstleistungen noch in Bezug auf Rechnungen, den Kunden, seine
                Verpflichtungen aus einem Vertrag auszusetzen oder die Auflösung des Vertrags zu verlangen, außer wie in
                Artikel 9 vorgesehen.</p>
            <h2>7. HAFTUNG</h2>
            <p>7.1. Die Haftung von FRIGERA in Bezug auf die von ihr gelieferten Waren ist auf die Bestimmungen von
                Artikel 6.4 beschränkt. FRIGERA haftet nicht oder ist nicht
                ersatzpflichtig für immaterielle, indirekte oder Folgeschäden, einschliesslich (aber nicht beschränkt
                auf) entgangenen Gewinn, entgangenen Umsatz, Einnahmenverlust,
                Produktionseinschränkungen, Verwaltungs- oder Personalkosten, eine Erhöhung der Gemeinkosten, Verlust
                von Kunden oder Ansprüche Dritter, es sei denn, es liegt
                Betrug, Vorsatz oder grobe Fahrlässigkeit vor.</p>
            <p>7.2. FRIGERA übernimmt keine Verantwortung für Mängel, die an den gelieferten Waren durch Verschulden
                oder Handlungen des Kunden oder Dritter, durch mangelnde
                Wartung, normalen Verschleiß, unsachgemäßen Gebrauch, mangelnde Sorgfalt oder durch äußere Einflüsse
                oder Veränderungen durch den Kunden oder Dritte entstanden
                sind.</p>
            <p>7.3. FRIGERA übernimmt auch keine Verantwortung für geringfügige Abweichungen in Farbe oder Abmessungen
                der gelieferten Ware, soweit diese nicht verhindert werden
                konnten, allgemein anerkannt sind oder materialspezifisch sind.</p>
            <p>7.4. FRIGERA haftet weder für Druck-, Schreib- und Zählfehler und/oder Unklarheiten in Angeboten oder
                Bestellungen noch für deren Folgen.</p>
            <p>7.5. Im Haftungsfall ist FRIGERA niemals verpflichtet, einen höheren Schaden als den Rechnungsbetrag
                (ohne Mehrwertsteuer) der gelieferten Ware oder eines anteiligen Teils
                davon zu ersetzen. Für den Fall, dass FRIGERA für den betreffenden Schaden versichert ist, ist ihre
                Haftung auf den Betrag beschränkt, der vom Versicherer im Rahmen
                der Policen im jeweiligen Fall ausbezahlt wird.</p>
            <h2>8. HÖHERE GEWALT</h2>
            <p>8.1. Höhere Gewalt auf Seiten von FRIGERA liegt in jedem Fall vor, wenn FRIGERA nach Abschluss des
                Vertrags daran gehindert wird, seine Verpflichtungen aus diesem
                Vertrag oder seine Vorbereitungen vollständig und/oder rechtzeitig zu erfüllen, und zwar aufgrund
                unvorhersehbarer Umstände, wie Feuer, Wasserschäden,
                Naturkatastrophen, Witterungseinflüsse, Streiks, Betriebsbesetzungen, Einfuhr- und
                Ausfuhrbeschränkungen, behördliche Maßnahmen, Maschinendefekte, verspätete
                Lieferung der verkauften Waren, der für die verkauften Waren benötigten Rohstoffe und/oder
                Hilfsstoffe,...; dies alles sowohl im Unternehmen von FRIGERA als auch bei
                Dritten, von denen FRIGERA die Waren und/oder die benötigten Materialien oder Rohstoffe ganz oder
                teilweise beziehen muss, sowie während der Lagerung oder des
                Transports, ob unter eigener Verwaltung oder nicht, und darüber hinaus aufgrund aller anderen Ursachen,
                die ohne Verschulden oder Risiko von FRIGERA entstehen.</p>
            <p>8.2. Im Falle höherer Gewalt wird die Partei, die einen Schaden durch die höhere Gewalt erleidet, die
                andere Partei unverzüglich (und in jedem Fall innerhalb einer Frist von 8
                Kalendertagen nach Eintritt der höheren Gewalt) per Einschreiben benachrichtigen. Ab diesem Zeitpunkt
                wird die Erfüllung der von der höheren Gewalt betroffenen
                Verpflichtungen für die Dauer der höheren Gewalt ausgesetzt. Die Partei, die unter der höheren Gewalt
                leidet, wird alle Anstrengungen unternehmen, um die Verzögerung
                auf ein Minimum zu reduzieren und die andere Partei über die zu diesem Zweck getroffenen Maßnahmen
                informieren. Die Parteien haben die Möglichkeit, den Vertrag
                ohne Entschädigung von einer Partei zur anderen zu kündigen, wenn die höhere Gewalt andauert oder
                festgestellt wird, dass sie für einen Zeitraum von mindestens 90
                Tagen andauern wird.</p>
            <h2>9. KÜNDIGUNG</h2>
            <p>9.1 Ohne Einschränkung anderer Rechte oder Rechtsmittel kann jede Partei einen Vertrag mit sofortiger
                Wirkung, ohne vorherige gerichtliche Intervention und ohne
                Verpflichtung zur Entschädigung durch schriftliche Mitteilung an die andere Partei kündigen, wenn: <br>
                (a) die andere Partei einen wesentlichen Verstoß gegen eine Bestimmung des Vertrags begeht und (falls
                ein solcher Verstoß wiedergutzumachen ist) diesen Verstoß nicht
                innerhalb von 8 Kalendertagen nach schriftlicher Inverzugsetzung behebt;<br>
                (b) die andere Partei eine Reihe geringfügiger oder anhaltender Verstöße gegen eine Bestimmung des
                Vertrags begeht;<br>
                (c) gegen die andere Partei ein Insolvenzverfahren eingeleitet wurde oder ihre Tätigkeit eingestellt
                wird (oder einzustellen droht); oder<br>
                (d) sich die finanzielle Lage der anderen Vertragspartei so verschlechtert, dass vernünftigerweise davon
                ausgegangen werden kann, dass die Fähigkeit dieser Vertragspartei,
                ihre Verpflichtungen aus dem Vertrag angemessen zu erfüllen, beeinträchtigt ist.</p>
            <p>9.2. Ohne Einschränkung anderer Rechte oder Rechtsmittel und unbeschadet ihres Rechts auf Entschädigung
                kann FRIGERA ohne vorherige gerichtliche Intervention und ohne
                Verpflichtung zur Zahlung von Entschädigungen, jedoch nach vorheriger Inverzugsetzung: <br>
                (a) die Lieferung der Waren im Rahmen des Vertrags aussetzen, wenn der Kunde einem der in Artikel 9.1
                (a) bis 9.1 (d) genannten Ereignisse unterliegt oder wenn der
                Kunde einen nach dem Vertrag fälligen Betrag am Fälligkeitstag der Zahlung nicht zahlt;<br>
                (b) einen Vertrag mit sofortiger Wirkung durch schriftliche Mitteilung an den Kunden auflösen, wenn der
                Kunde den fälligen Betrag nicht am Fälligkeitstag an FRIGERA
                zahlt. Im letzteren Fall schuldet der Kunde automatisch eine Entschädigung in Höhe von 10% des Angebots-
                oder Auftragspreises zuzüglich der eventuellen
                entstandenen Kosten, unbeschadet des Rechts von FRIGERA, den dem Kunden tatsächlich entstandenen Schaden
                zu ersetzen.</p>
            <p>9.4. Bei Kündigung des Vertrags, gleich aus welchem Grund, hat der Kunde FRIGERA unverzüglich alle
                offenen Rechnungen und Zinsen zu zahlen. FRIGERA kann vom
                Kunden nach eigenem Ermessen verlangen, alle gelieferten Waren zurückzusenden oder sofort zu zahlen.</p>
            <p>9.5. Die Kündigung des Vertrags berührt nicht die Rechte und Rechtsmittel der Parteien, die sich zum
                Zeitpunkt der Kündigung ergeben, einschließlich des Rechts,
                Schadensersatz in Bezug auf einen Verstoß gegen den Vertrag zu verlangen, der am oder vor dem Datum der
                Kündigung bestand.</p>
            <p>9.6. Jede Bestimmung des Vertrags, die ausdrücklich oder implizit beabsichtigt ist, bei oder nach der
                Kündigung wirksam zu werden oder zu bleiben, bleibt in vollem Umfang
                in Kraft und wirksam.</p>
            <h2>10. GEISTIGES EIGENTUM</h2>
            <p>10.1. Sofern nichts anderes schriftlich vereinbart wurde, werden alle Waren, wenn sie in Verpackungen
                verkauft werden, nur in der von FRIGERA gelieferten Verpackung
                verkauft oder weiterverkauft und unter keinen Umständen dürfen andere Marken auf den Waren
                gekennzeichnet oder angebracht werden.</p>
            <p>10.2. Der Kunde erkennt an, dass die geistigen Eigentumsrechte bei FRIGERA liegen. Der Kunde erwirbt
                keine geistigen Eigentumsrechte, die mit den Waren verbunden sind,
                und hat kein Recht, geistige Eigentumsrechte außer im Zusammenhang mit dem Verkauf der Waren in
                Verbindung mit diesen Allgemeinen Geschäftsbedingungen zu
                nutzen.</p>
            <p>10.3. Der Kunde wird keine Handlungen vornehmen, die die geistigen Eigentumsrechte direkt oder indirekt
                schädigen könnten.</p>
            <p>10.4. Jedes von FRIGERA zur Verfügung gestellte Werbematerial, ob in gedruckter oder elektronischer Form,
                ist Eigentum von FRIGERA und muss auf Anfrage zurückgegeben
                werden.</p>
            <p>10.5. Alle geistigen Eigentumsrechte an den Bildern und Fotos der Waren sind FRIGERA vorbehalten und
                dürfen nicht in nicht von FRIGERA zur Verfügung gestellten
                Werbematerialien gezeigt, verwendet oder aufgenommen werden, es sei denn, FRIGERA hat dies ausdrücklich
                schriftlich und im Voraus genehmigt.</p>
            <p>10.6. Die in diesem Artikel dargelegten Einschränkungen gelten für jedes Medium und in jeder Weise, wie
                Drucksachen, Websites, elektronische Plattformen, soziale Netzwerke
                oder andere Methoden der elektronischen oder digitalen Kommunikation.</p>
            <p>10.7. Insoweit die Verpackung oder deren Gestaltung/Konzept vom Kunden geliefert wird, gilt dieser als
                Inhaber der geistigen Eigentumsrechte an dieser Verpackung. In diesem
                Fall stellt der Kunde FRIGERA von allen Ansprüchen (auch Dritter) wegen eines angeblichen Verstoßes
                dieser geistigen Eigentumsrechte frei.</p>
            <h2>11. NICHTIGKEIT</h2>
            <p>11.1. Die Nichtigkeit einer oder mehrerer Bestimmungen dieser Geschäftsbedingungen berührt nicht die
                Rechtswirksamkeit der übrigen Bestimmungen. Die
                Nichtinanspruchnahme oder teilweise Geltendmachung bestimmter vertraglicher Rechte durch FRIGERA stellt
                keine nachteilige Anerkennung oder einen Verzicht auf
                Rechte dar.</p>
            <p>11.2. Wenn eine Bestimmung oder Teilbestimmung dieser Bedingungen oder des Vertrags ungültig,
                rechtswidrig oder nicht durchsetzbar ist oder wird, gilt sie als geändert,
                soweit dies erforderlich ist, um sie gültig, rechtmäßig und durchsetzbar zu machen. Ist eine solche
                Änderung nicht möglich, so gilt die betreffende Bestimmung oder
                Teilbestimmung als gestrichen. Jede Änderung oder Streichung einer Bestimmung oder eines Teils einer
                Bestimmung gemäß dieser Klausel berührt nicht die Gültigkeit
                und Durchsetzbarkeit der übrigen Bedingungen oder des Vertrags.</p>
            <h2>12. STREITIGKEITEN</h2>
            <p>12.1. Diese Allgemeinen Geschäftsbedingungen unterliegen belgischem Recht. Im Streitfall sind nur die
                Gerichte des Bezirks Antwerpen, Abteilung Hasselt, zuständig.</p>
            <h2>13. DATENSCHUTZ UND VERARBEITUNG PERSONENBEZOGENER DATEN (DSGVO)</h2>
            <p>13.1. FRIGERA sammelt von der Erstellung des Angebots oder des Bestellformulars bis zur Rechnungsstellung
                alle Daten, die für die Ausführung des Vertrags erforderlich
                sind, einschließlich des Namens des Kunden, seiner Adresse, Telefonnummern, E-Mail-Adresse und aller
                anderen Informationen über den Kunden. Der Kunde hat folgende
                Rechte: das Recht auf Zugang, das Recht auf Berichtigung, das Recht auf Löschung, das Recht auf
                Einschränkung der Verarbeitung, das Recht auf Übertragbarkeit
                personenbezogener Daten, das Recht auf Widerspruch und das Recht, nicht einer automatisierten
                Entscheidungsfindung unterworfen zu werden. Der Kunde kann die
                vorgenannten Rechte jederzeit geltend machen und dann genügt es, FRIGERA unter den im Bestellformular
                und/oder Angebot angegebenen Kontaktdaten zu kontaktieren.
                Bei Beschwerden bezüglich der Verarbeitung personenbezogener Daten kann sich der Kunde an FRIGERA
                wenden, um eine interne Lösung zu finden, oder eine
                Beschwerde bei der Datenschutzbehörde einzureichen</p>

        </ng-container>

    </div>

</div>