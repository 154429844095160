import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.scss',
        'mobile/home.mobile.hero.scss',
        'mobile/home.mobile.roots.scss',
        'mobile/home.mobile.packaging.scss',
        'mobile/home.mobile.products.scss',
        'mobile/home.mobile.team.scss',
        'mobile/home.mobile.contact.scss'
    ]
})
export class HomeComponent implements OnInit {
    productSections: any = [];
    team: any = [];
    lang: string = 'en';

    constructor(private DataService: DataService, private TranslatorService: TranslatorService) { }

    ngOnInit(): void {
        this.lang = this.TranslatorService.getCurrentLanguage();
        this.productSections = this.DataService.getData('products');
        this.team = this.DataService.getData('team');
    }
}
