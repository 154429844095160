import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ngfModule, ngf } from 'angular-file';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { prefixLangPipe, SlugsPipe, TranslatePipe } from './services/translator.service';
import { HomeComponent } from './pages/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContactComponent } from './pages/contact/contact.component';
import { InitService } from './services/init.service';
import { FooterComponent } from './components/footer/footer.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { WebshopPopupComponent } from './components/webshop-popup/webshop-popup.component';
import { EmailObfuscationPipe, SafePipe } from './utils/pipes';

@NgModule({
    declarations: [
        AppComponent,
        TranslatePipe,
        HomeComponent,
        NavigationComponent,
        prefixLangPipe,
        ProductsComponent,
        ContactComponent,
        SlugsPipe,
        SafePipe,
        EmailObfuscationPipe,
        FooterComponent,
        CookiesComponent,
        PrivacyPolicyComponent,
        CookiePolicyComponent,
        TermsComponent,
        WebshopPopupComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AngularMyDatePickerModule,
        ngfModule,
        CommonModule,

        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        // { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        // { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [InitService] },
        TranslatePipe,
        prefixLangPipe,
        SlugsPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
