import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DataService } from './data.service';
import { RoutingService } from './routing.service';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private translations$ = new BehaviorSubject<Object>({});
    private language$ = new BehaviorSubject<any>(null);
    private ready: boolean = false;
    private publicLanguage$ = new BehaviorSubject<string>(null);

    constructor(public Route: ActivatedRoute, private DataService: DataService) {
        const publicLanguage = localStorage.getItem('publicLanguage');
        if (publicLanguage) {
            this.setPublicLanguage(publicLanguage);
        }
    }

    public init(language: any) {
        this.setLanguage(language);
        this.translations$.next(this.DataService.getData('strings', language));
        this.ready = true;
    }

    public getTranslation(key: string): string {
        if (!key || !this.ready) return key;
        key = key.toLowerCase();
        if (this.translations$.getValue()[key]) {
            return this.translations$.getValue()[key];
        } else {
            return key;
        }
    }

    public getTranslations(): Observable<Object> {
        return this.translations$.asObservable();
    }

    public getLanguage(): Observable<any> {
        return this.language$.asObservable();
    }

    public setLanguage(language: any) {
        this.language$.next(language);
    }

    public getPublicLanguage(): string | null {
        let browserLang = null;
        let localstorageLang = null;
        if (navigator) {
            browserLang = navigator.language || navigator['userLanguage'];
        }
        if (browserLang && browserLang.indexOf('fr') != -1) {
            browserLang = 'fr';
        } else browserLang = null;
        if (localStorage.getItem('publicLanguage')) {
            localstorageLang = localStorage.getItem('publicLanguage');
        }
        return this.publicLanguage$.getValue() || localstorageLang || browserLang || 'en';
    }

    public setPublicLanguage(language: any) {
        this.publicLanguage$.next(language);
        localStorage.setItem('publicLanguage', language);
    }

    public getCurrentLanguage() {
        return this.language$.getValue() || this.publicLanguage$.getValue() || 'en';
    }

    public getLocale(language?) {
        if (!language) {
            language = this.language$.getValue();
        }
        switch (language) {
            case 'nl':
                return 'nl-BE';
            case 'fr':
                return 'fr-FR';
            case 'en':
                return 'en-GB';
            default:
                return 'nl-BE';
        }
    }
}

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) { }
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({ name: 'prefixLang' })
export class prefixLangPipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) { }
    public transform(path: string, noTrailingSlash?: boolean): string {
        return `/${this.TranslatorService.getCurrentLanguage()}${noTrailingSlash ? '' : '/'}${path}`;
    }
}

@Pipe({ name: 'slugs' })
export class SlugsPipe implements PipeTransform {
    constructor(private RoutingService: RoutingService) { }
    public transform(path: string): string {
        return this.RoutingService.slugs(path);
    }
}
